/* customCalendar.css */

.rbc-calendar {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rbc-toolbar {
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.rbc-toolbar label {
  font-size: 16px;
  color: #000;
}

.rbc-btn-group button {
  color: #000;
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  margin: 0;
}

.rbc-btn-group button:active {
  background-color: #ddd;
}

.rbc-off-range {
  background-color: #f9f9f9;
}

.rbc-off-range .rbc-day-bg {
  stroke: none;
}

.rbc-header {
  padding: 6px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}
.rbc-today {
  background-color: rgba(52, 188, 52, 0.2) !important;
}

.rbc-day-slot .rbc-event {
  border-radius: 4px;
  padding: 2px 5px;
  background-color: rgb(52, 188, 52);
  color: #fff;
}
.rbc-event-content {
  background-color: rgb(52, 188, 52) !important;
  color: #fff !important;
  border-color: rgb(52, 188, 52) !important;
}
